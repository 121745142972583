import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "input", "show", "hidden" ]
  static values = { on: String }

  connect() {
    this.toggle()
    document.addEventListener("turbo:render", this.toggle.bind(this))
  }

  disconnect() {
    document.removeEventListener("turbo:render", this.toggle.bind(this))
  }

  toggle() {
    const checked = this.inputTarget.checked
    if (checked) {
      this.handleCheckbox(checked)
      return
    }

    const value = this.inputTarget.value
    if (value === this.onValue) {
      this.show()
    } else {
      this.hide()
    }
  }

  handleCheckbox(checked) {
    if (checked) {
      this.show()
    } else {
      this.hide()
    }
  }

  hide() {
    this.showTarget.classList.add("hidden")
    this.hiddenTarget.classList.remove("hidden")
  }

  show() {
    this.showTarget.classList.remove("hidden")
    this.hiddenTarget.classList.add("hidden")
  }
}
